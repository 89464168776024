"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isEthereumAddress;
var _assertString = _interopRequireDefault(require("./util/assertString"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var eth = /^(0x)[0-9a-f]{40}$/i;
function isEthereumAddress(str) {
  (0, _assertString.default)(str);
  return eth.test(str);
}
module.exports = exports.default;
module.exports.default = exports.default;