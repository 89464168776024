"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isLowercase;
var _assertString = _interopRequireDefault(require("./util/assertString"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function isLowercase(str) {
  (0, _assertString.default)(str);
  return str === str.toLowerCase();
}
module.exports = exports.default;
module.exports.default = exports.default;